<template>
    <div>
        <el-dialog :title="title" :before-close="handleClose" :visible.sync="dialog" direction="rtl"
            custom-class="demo-drawer" ref="drawer" size="60%">
            <el-form :model="addEditForm" label-width="100px" ref="addEditForm">
                <el-row :gutter="24">
                    <el-col :md="8" :sm="8">
                        <el-form-item label="类型" prop="type">
                            <el-radio-group v-model="addEditForm.type" >
                                <el-radio :label="1">接口</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :md="8" :sm="8">
                        <el-form-item label="数据" prop="interfaceData">
                            <el-select v-model="addEditForm.interfaceData" placeholder="请选择" @change="AddSelectDept"  value-key="id"
                                    :disabled="editShow" >
                                <el-option v-for="item in interfaceList" 
                                :key="item.id" :label="item.interfaceName"  
                                    :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="8" :sm="8">
                        <el-form-item label="请求方式" prop="interfaceMethod">
                            <el-input v-model="addEditForm.interfaceMethod" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="24">
                    <el-col :md="8" :sm="8">
                        <el-form-item label="内容名称" prop="name">
                            <el-input v-model="addEditForm.name" :disabled="editShow" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="16" :sm="16">
                        <el-form-item label="请求路径" prop="interfaceHost">
                            <el-input v-model="addEditForm.interfaceHost" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :md="8" :sm="8">
                        <el-form-item label="接口服务" prop="interfaceService">
                            <el-input v-model="addEditForm.interfaceService" disabled></el-input>
                        </el-form-item>
                    </el-col> -->
                </el-row>

                <el-row :gutter="24">
                    <el-col :md="8" :sm="8">
                        <el-form-item label="小标题" prop="contentTitle">
                            <el-input v-model="addEditForm.contentTitle" :disabled="this.catType === 3"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="24">
                    <el-col :md="12" :sm="12">
                        <el-form-item label="headers参数" prop="headersParameters">
                            <el-input v-model="addEditForm.headersParameters" disabled=""></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="12" :sm="12">
                        <el-form-item label="用户参数" prop="userValue">
                            <el-input v-model="addEditForm.userValue" placeholder="示例：参数1=值1&参数2=值2" :disabled="editShow"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row :gutter="24">
                    <el-col :md="12" :sm="12">
                        <el-form-item label="请求参数" prop="requestParameters">
                            <el-input v-model="addEditForm.requestParameters" placeholder="示例：参数1=值1&参数2=值2"  disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>


                <div class="button-center" >
                    <el-button v-for="(item, index) in dataSourceButton" :key="index" @click="getButtonValue(item)" >
                        {{ getKey(item) }}
                    </el-button>
                </div>
                <div class="container">
                    <div class="side left" v-html="responseExample"></div>
                    <textarea class="center" placeholder="请输入" v-model="modelValue" :disabled="editShow"></textarea>
                    <div class="side right" v-html="example"></div>
                </div>


            </el-form>
            <div slot="footer" class="dialog-footer" v-if="this.catType != 3">
                <el-button @click="cancelForm">取 消</el-button>
                <!-- @click="$refs.drawer.closeDrawer()" -->
                <el-button type="primary" @click="addFormButton()" :loading="loading">{{ loading ? '提交中 ...' : '确 定'
                }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    saveOrUpdateInfomaApi,
    selectInfomaByIdApi
} from "@/api/work/bots/bots.js";
export default {
    data() {
        return {

            dialog: false,
            loading: false,
            editShow: false,
            title: null,
            addEditForm: {
                type: 1
            },
            catType: 1,
            interfaceList: [],
            interfaceData: {},
            dataSourceButton: [],
            modelValue: "",
            example: "",
            responseExample: "",
            botsId: null,

        };
    },
    methods: {
        show(row, type) {
            this.catType = type
            this.dialog = true;
            this.botsId = row.botsId
            this.addEditForm.botsId = row.botsId
            this.interfaceList = this.$parent.getInterfaceList
            if (type == 2 || type == 3) {
                //编辑 
                this.editShow = true
                this.selectByIdButton(row)
            }

        },
        handleClose(done) {
            if (this.loading) {
                return;
            }
            this.$confirm("关闭")
                .then(_ => {
                    this.cancelForm();
                    this.dialog = false;
                })
                .catch(_ => { });
        },
        cancelForm() {
            this.loading = false;
            this.dialog = false;
            this.catType = 1
            this.editShow = false;
            this.botsId = null
            this.$refs['addEditForm'].resetFields();

            this.dataSourceButton = []
            this.modelValue = ""
            this.example = ""
            this.responseExample = ""
            this.addEditForm = {
                type: 1,
                refId: null
            }
            this.$parent.selectListButton(this.addEditForm);
        },
        addFormButton() {
            this.addEditForm.templateNotifications = this.modelValue
            this.addEditForm.resultExample = this.example
            console.log(this.responseExample)
            this.addEditForm.responseExample = JSON.stringify(this.responseExample)
            saveOrUpdateInfomaApi(this.addEditForm).then(res => {
                this.cancelForm()
            })
        },

        //查询详情
        selectByIdButton(row) {
            selectInfomaByIdApi(row.id).then(res => {
                this.addEditForm = res.result
                this.addEditForm.interfaceData = this.addEditForm
                this.addEditForm.interfaceMethod = res.result.resourceMethod
                this.addEditForm.interfaceHost = res.result.interfaceHost
                this.addEditForm.interfaceService = res.result.interfaceService

                this.addEditForm.resourceId = res.result.uniqueId
                this.addEditForm.interfaceName = res.result.resourceName
                this.addEditForm.interfaceHost = res.result.resourceContent
                this.addEditForm.type = parseInt(res.result.type)
                this.modelValue = res.result.templateNotifications
                this.example = res.result.resultExample
                this.responseExample = res.result.responseExample
            })
        },
        addDialogClosed() {
            // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
            this.$refs['addEditForm'].resetFields();
        },
        clearSelectDept(){
            this.addEditForm.interfaceData={}
            this.addEditForm.interfaceMethod = ''
            this.addEditForm.interfaceHost = ''
            this.addEditForm.interfaceService = ''
            this.addEditForm.resourceId = ''
            this.addEditForm.resourceName = ''
            this.addEditForm.resourceContent = ''
            this.addEditForm.resourceMethod = ''
            this.addEditForm.resultKey = ''
            this.addEditForm.headersParameters = ''
            this.responseExample = ""
        },
        AddSelectDept(row) {
            if(row.isPolymorphic===1){
                this.$message.error("暂时不支持多形态参数");
                this.clearSelectDept()
                return
            }
            this.catType=this.catType
            this.addEditForm.interfaceMethod = row.interfaceMethod
            this.addEditForm.interfaceHost = row.interfaceHost
            this.addEditForm.interfaceService = row.interfaceService
            this.addEditForm.resourceId = row.uniqueId
            this.addEditForm.resourceName = row.interfaceName
            this.addEditForm.resourceContent = row.interfaceHost
            this.addEditForm.resourceMethod = row.interfaceMethod
            this.addEditForm.resultKey = row.resultKey
            this.addEditForm.headersParameters = row.headersParameters
            const key = row.resultKey;
            var jsonData = JSON.parse(row.responseParams);
            this.responseExample = jsonData[key]
            this.buildButtonList(jsonData[key])
        },
        buildButtonList(row) {
            console.log(row)
          
            var array = Object.keys(row).map(function (key) {
                var obj = {};
                obj[key] = row[key]
                return obj;
            })
            this.dataSourceButton = array;
            this.dataSourceButton.push({ 换行: "<br/>" })

        },

        getKey(item) {
            return Object.keys(item)[0];
        },
        getButtonValue(item) {
            const key = this.getKey(item);
            this.modelValue = this.modelValue + `\$\{${key}\}`;
            let exampleValue = this.modelValue;
            const valueMap = this.dataSourceButton.reduce((acc, obj) => {
                const key = Object.keys(obj)[0];
                acc[key] = obj[key];
                return acc;
            }, {});
            for (const key in valueMap) {
                const patten = new RegExp(`\\$\{${key}\}`, "g")
                exampleValue = exampleValue.replace(patten, valueMap[key]);
            }
            this.example = exampleValue

        }
    }
};
</script>
<style lang="less" scoped>
.container {
    display: flex;
}



.button-center {
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
    height: 100%;
    /* 根据需要设置高度 */
}

.container {
    display: flex;
    /* 使用Flex布局 */
    justify-content: space-between;
    /* 两端对齐，保持两侧间距相等 */
}

.side {
    flex: 3;
    /* 左侧和右侧盒子宽度为3份 */
    background-color: lightblue;
}

.left {
    /* 这里可以添加其他样式，如背景色、边距等 */
}

.center {
    flex: 4;
    /* 中间盒子宽度为4份 */
    resize: none;
    /* 禁止调整textarea大小，可选 */
    overflow: auto;
    /* 当内容超出时显示滚动条 */
}
</style> 



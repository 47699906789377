import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/notification'
const botsListApi =(params)=>getAction(baseURL+"/botsList",params);
const saveOrUpdateBotsApi=(params)=>postAction(baseURL+"/saveOrUpdateBots",params);

const selectBotsByIdApi=(params)=>getByIdAction(baseURL+'/selectBotsById',params);

const infomaListApi=(params)=>getAction(baseURL+'/infomaList',params);

const getInterfaceListApi=(params)=>getAction(baseURL+'/getInterfaceList',params);

const saveOrUpdateInfomaApi=(params)=>postAction(baseURL+'/saveOrUpdateInfoma',params);


const getSingleDataApi=(params)=>postAction(baseURL+"/getSingleData",params);


const selectInfomaByIdApi=(params)=>getByIdAction(baseURL+'/selectInfomaById',params);
const sendSingleDataApi=(params)=>postAction(baseURL+"/sendSingleData",params);
const deleteByIdInfomaApi=(params)=>deleteByIdAction(baseURL+"/deleteByIdInfoma",params);

export{
    deleteByIdInfomaApi,
    selectInfomaByIdApi,
    sendSingleDataApi,
    getSingleDataApi,
    saveOrUpdateInfomaApi,
    infomaListApi,
    botsListApi,
    saveOrUpdateBotsApi,
    selectBotsByIdApi,
    getInterfaceListApi
}
